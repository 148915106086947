// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Card from './components/Card';
import CompanyCard from './components/CompanyCard';
import DefualtCard from './components/DefualtCard';

function App() {
  return (
    <Router>
      <Routes>
      
        <Route path="/company/:companyId" element={<CompanyCard />} />
        <Route path="/user/:userId" element={<Card />} />
        <Route path="*" element={<DefualtCard />} />
      </Routes>
    </Router>
  );
}

export default App;
