import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaEnvelope, FaPhone, FaSms, FaWhatsapp } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
function CompanyCard() {
  const { companyId } = useParams(); 
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [contactFileUrl, setContactFileUrl] = useState(null);

  
  useEffect(() => {
    const fetchUserData = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_HOST_API+`/api/business-card/company/${companyId?companyId+'/':''}`);
            setProfileData(response.data);
            setContactFileUrl( () => {
                  const vcfData = `
                  BEGIN:VCARD
                  VERSION:3.0
                  FN:${response.data.name}
                  TEL:${response.data.phone}
                  END:VCARD`.trim();
                  const blob = new Blob([vcfData], { type: 'text/vcard' });
                  // Create a URL for the Blob
                  return URL.createObjectURL(blob);
              });
            
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    fetchUserData();
    }, [companyId]);
  if (loading) {
    return <div>Loading...</div>;
  }

  else{
  return (
    <div className="max-w-xl mx-auto  bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Top section with logo and curved background */}
      <div className="relative bg-blue-600 text-white rounded-b-[20px]">
        <div className="flex justify-center h-[130px] ">
        </div>
        <div className="absolute inset-x-0 -bottom-16 flex justify-center ">
          
          <img
            className="rounded-full border-4 border-white w-32 h-32 bg-blue-600"
            src={
              process.env.REACT_APP_HOST_API+profileData.logo
            }
            alt="profile"
          />
        </div>
      </div>

      {/* User Information Section */}
      <div className="pt-16 text-center px-6 pb-6">
        <h2 className="text-xl font-semibold text-blue-600">{profileData.name}</h2>
        <p className="text-blue-600 text-sm">{profileData.activity}</p>
        <p className="mt-2 text-gray-700 text-sm">
          {profileData.bio}
        </p>
      </div>
      <div className="w-full flex justify-center pt-5 pb-5">
    
    <a href={profileData.facebook_url} className="mx-5" target="_blank" rel="noreferrer">
          <div aria-label="Facebook" role="img">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#718096" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook">
              <path d="M12 2.03998C6.5 2.03998 2 6.52998 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.84998C10.44 7.33998 11.93 5.95998 14.22 5.95998C15.31 5.95998 16.45 6.14998 16.45 6.14998V8.61998H15.19C13.95 8.61998 13.56 9.38998 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96C15.9164 21.5878 18.0622 20.3855 19.6099 18.57C21.1576 16.7546 22.0054 14.4456 22 12.06C22 6.52998 17.5 2.03998 12 2.03998Z"/>
              </svg>
          </div>
      </a>
  
      <a href={profileData.instagram_url} className="mx-5" target="_blank" rel="noreferrer">
          <div aria-label="Instagram" role="img">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#718096" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram">
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
              </svg>
          </div>
      </a>
  
      <a href={profileData.tiktok_url} className="mx-5" target="_blank" rel="noreferrer">
          <div aria-label="TikTok" role="img">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"  fill="none" stroke="#718096" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" className="feather feather-tiktok">
                  <path d="M216.26,79.61c-14.3-1.43-27-8.57-35.33-19.38a57.45,57.45,0,0,1-11-32.66H141.3V173.43a27.36,27.36,0,1,1-18.47-26v-33a60.33,60.33,0,0,0-29.33-3.26C71,116.71,52.57,133,48.13,154.77a66.2,66.2,0,0,0,29.67,67.86,65.11,65.11,0,0,0,32,8.53c36.33,0,65.87-29.62,65.87-66V93.13a89.38,89.38,0,0,0,47.28,14.13V79.61Z"></path>
              </svg>
          </div>
      </a>
    </div>
      <ul  className="divide-y divide-gray-100 px-10">
        <a href={"tel:"+profileData.phone}>
            <li key={"phone"} className="flex justify-between gap-x-6 py-3" >
              <div className="flex min-w-0 gap-x-4">
              <FaPhone className='text-5xl text-blue-600' />  
              <div className="min-w-0 flex-auto text-left">
                  <p className="text-lg font-semibold leading-6 text-gray-900">Appelez</p>
                  <p className="mt-1 truncate text-base leading-5 text-gray-500">{profileData.phone}</p>
                </div>
              </div>
            </li>
          </a>
          
          <a href={"sms:"+profileData.phone}>
            <li key={'sms'} className="flex justify-between gap-x-6 py-3">
              <div className="flex min-w-0 gap-x-4">
              <FaSms className='text-5xl text-blue-600'  />
                <div className="min-w-0 flex-auto text-left">
                  <p className="text-lg font-semibold leading-6 text-gray-900">SMS
                  </p>
                  <p className="mt-1 truncate text-base leading-5 text-gray-500">{profileData.phone}</p>
                </div>
              </div>
            </li></a>
            <a href={"mailto:"+profileData.email}>
            <li key={'email'} className="flex justify-between gap-x-6 py-3">
              <div className="flex min-w-0 gap-x-4">
              <FaEnvelope className='text-5xl text-blue-600'  />
                <div className="min-w-0 flex-auto text-left">
                  <p className="text-lg font-semibold leading-6 text-gray-900">EMAIL
                  </p>
                  <p className="mt-1 truncate text-base leading-5 text-gray-500">{profileData.email}</p>
                </div>
              </div>
            </li></a>

          <a href={"https://wa.me/"+profileData.phone}>
            <li key={"whatsapp"} className="flex justify-between gap-x-6 py-3" >
              <div className="flex min-w-0 gap-x-4">
                <FaWhatsapp className='text-5xl text-blue-600' />
                <div className="min-w-0 flex-auto text-left">
                  <p className="text-lg font-semibold leading-6 text-gray-900">WhatsApp</p>
                  <p className="mt-1 truncate text-base leading-5 text-gray-500">{profileData.phone}</p>
                </div>
              </div>
            </li>
            </a>



        </ul> 
     
     
  <a className="w-full text-center bg-blue-600 text-sm text-white pt-3 pb-4 px-8 block  mt-4"
            href={contactFileUrl} download="contact.vcf"
            >Add to Contact</a>
  
    </div>
  );
}
}

export default CompanyCard;
